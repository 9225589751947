import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
import TeamMembers from '@interness/web-core/src/components/modules/TeamMembers';
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Team`}</h1>
      <hr></hr>
      <TeamMembers mdxType="TeamMembers" />
      <hr></hr>
      <div style={{
        textAlign: 'center'
      }}>
  <CallToAction mdxType="CallToAction" />
      </div>
      <hr></hr>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      